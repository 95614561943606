import React from 'react';
import '../../assets/styles/elements/Gallery.scss';

function Gallery () {
  return (
    <main className='gallery_main'>
      <div className='items_wrapper'>
      <div className="content">
      <h1>Photography</h1>
      <p>
        Alongside motion pictures, we also provide photography services 
        to tell stories through still images—a medium that freezes moments 
        in time to convey emotions and preserve memories. 
        If you are interested in a photoshoot session for various occasions 
        such as family photos, event details, anniversary, engagement, wedding, or portrait, 
        reach out to us via email or direct message as listed on this website.
      </p>
      <section className='gallery_section'>
        <div className="imageGallery">
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_1.jpg"
              alt="Showcase #1"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_2.jpg"
              alt="Showcase #2"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_3.jpg"
              alt="Showcase #3"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_4.jpg"
              alt="Showcase #4"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_5.jpg"
              alt="Showcase #5"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_6.jpg"
              alt="Showcase #6"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_7.jpg"
              alt="Showcase #7"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_8.jpg"
              alt="Showcase #8"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_9.jpg"
              alt="Showcase #9"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_10.jpg"
              alt="Showcase #10"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_11.jpg"
              alt="Showcase #11"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_12.jpg"
              alt="Showcase #12"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_13.jpg"
              alt="Showcase #13"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_14.jpg"
              alt="Showcase #14"
              loading="lazy"
            />
          </button>
          <button>
            <img
              src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/gallery/gallery_img_15.jpg"
              alt="Showcase #15"
              loading="lazy"
            />
          </button>
        </div>
      </section>
      </div>
      </div>
    </main>
  );
}

export default Gallery;