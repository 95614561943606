import React from "react";
import '../assets/styles/Footer.scss';
import { NavLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Image from '../assets/images/site_logo_light.png';

function Footer() {
  return (
    <div className="footer">
      <Divider/>
      <Box sx={{ flexGrow: 1 }} className="footer-list-container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} className="first">
            <NavLink to='/'><img src={Image} alt="Site Logo" width="160px" /></NavLink>
            <p>An independent film studio based in Dallas, Texas, United States, with a passion for creating cinematic short and feature length films.</p>
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2} className="grid-parent">
            <div className="align-center">
              <ul>
                <p>SITE MAP</p>
                <NavLink to='/team'><li>Team</li></NavLink>
                <NavLink to='/works'><li>Works</li></NavLink>
                <NavLink to='/news'><li>News</li></NavLink>
                <NavLink to='/contact'><li>Contact</li></NavLink>
              </ul>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2} className="grid-parent">
            <div className="align-center">
              <ul>
                <p>PROJECTS</p>
                <NavLink to='/works'><li>Short Films</li></NavLink>
                <NavLink to='/works'><li>Photography</li></NavLink>
              </ul>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2} className="grid-parent">
            <div className="align-center">
              <ul>
                <p>LEGAL</p>
                <li>Terms of Use</li>
                <li>Privacy Notice</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className="social_links">
              <a href="https://www.youtube.com/@satosfilm" target="_blank" rel="noreferrer"><YouTubeIcon/></a>
              <a href="https://www.instagram.com/satos.film.photography/" target="_blank" rel="noreferrer"><InstagramIcon/></a>
            </div>
          </Grid>
        </Grid>
      </Box>
      <footer className="footer-copyright">
        <div className="contact">
          <p>© 2024 Sato's Film Studios. All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;