import React from "react";
import {useEffect} from 'react';
import {
  Filmography,
  Gallery,
} from "./elements";

function Works() {
  useEffect(() => {
    document.title = "Sato's Film Studios | Works";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
      <div className="page_header">
        <img src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_banner.png" alt=""></img>
      </div>
      <React.StrictMode>
        <Filmography />
        <Gallery />
      </React.StrictMode>
    </div>
  );
}
export default Works;