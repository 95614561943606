import React from 'react';
import '../../assets/styles/elements/Filmography.scss';

function Filmography () {
  return  (
    <div className='items_wrapper'>
      <div>
        <div className="content">
          <h1>Filmography</h1>
          <p>
            Our most recent work, <a className='works_link' href='https://youtu.be/YiCUlE8QHTM'>Leave It At The Door (2023)</a>,
             is a original thriller-romance film about a lonely delivery girl, 
             Sydney, who develops an unexpected relationship with her unseen customer. 
             The previous short film, <a className='works_link' href='https://youtu.be/c-L2XJOhKfA'>The Abyss (2023)</a>, has garnered 53,000+ organic views on the YouTube video platform.
          </p>
        </div>
      </div>

      <div className='works_list_grid'>
        <div className='grid_element'>
          <iframe src="https://www.youtube.com/embed/YiCUlE8QHTM?si=mjV18cIxyoo0UPn9" className='youtube-video' title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <a href='https://youtu.be/YiCUlE8QHTM' className='grid_link'>Leave It At The Door (2023)</a>
        </div>
        <div className='grid_element'>
          <iframe src="https://www.youtube.com/embed/c-L2XJOhKfA?si=aJNA6eeeYHo_xUby" className='youtube-video' title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <a href='https://youtu.be/c-L2XJOhKfA' className='grid_link'>The Abyss (2023)</a>
        </div>
        <div className='grid_element'>
          <iframe src="https://www.youtube.com/embed/fyWGqsqbw40?si=LOadgrfpSdwzv3QD" className='youtube-video' title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <a href='https://youtu.be/fyWGqsqbw40' className='grid_link'>Spring in Italy: Travel Film</a>
        </div>
      </div>
    </div>
  );
}

export default Filmography;