import React, { useRef, useState, useEffect } from 'react';
import '../assets/styles/Contact.scss';
import emailjs from '@emailjs/browser';
import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import InstagramIcon from '@mui/icons-material/Instagram';

function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  useEffect(() => {
    document.title = "Sato's Film Studios | Contact Us";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    setNameError(name === '');
    setEmailError(email === '');
    setMessageError(message === '');

    if (name !== '' && email !== '' && message !== '') {
      var templateParams = {
        name: name,
        email: email,
        message: message
      };

      console.log(templateParams);
      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_NAME, process.env.REACT_APP_EMAILJS_TEMPLATE_NAME, templateParams, process.env.REACT_APP_EMAILJS_TEMPLATE_PARAMS).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          console.log('FAILED...', error);
        },
      );
      setName('');
      setEmail('');
      setMessage('');
    }
  };

  return (
    <div>
      <div className="page_header">
        <img src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_banner.png" alt=""></img>
      </div>
      <div className="items_wrapper">
        <div className="contact_wrapper">
          <h1>Contact Us</h1>
          <p>
            Feel free to shoot us a message and say hello! We're always open to collaborations, inquiries, and new ideas.
          </p>
          <p>
            <EmailIcon/>Email: <span className='underline'>support<span className="blockspam" aria-hidden="true"> THIS TEXT IS HIDDEN </span>@satosfilm.com</span>
            <br/>
            <InstagramIcon/>Instagram: DM us @satos.film.photography
          </p>

          <Box
            ref={form}
            component="form"
            noValidate
            autoComplete="off"
            className='contact-form-wrapper'
          >
            <div className='form-flex'>
              <TextField
                required
                id="outlined-required"
                label="Your Name"
                placeholder="What's your name?"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                error={nameError}
                helperText={nameError ? "Please enter your name" : ""}
              />
              <TextField
                required
                id="outlined-required"
                label="Email / Phone"
                placeholder="How can we reach you?"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={emailError}
                helperText={emailError ? "Please enter your email or phone number" : ""}
              />
            </div>
            <TextField
              required
              id="outlined-multiline-static"
              label="Message"
              placeholder="Send us any inquiries or questions"
              multiline
              rows={7}
              className="body-form"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              error={messageError}
              helperText={messageError ? "Please enter the message" : ""}
            />
            <Button variant="contained" endIcon={<SendIcon />} onClick={sendEmail}>
              Send
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Contact;