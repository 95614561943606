import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import data from './data'
import Divider from '@mui/material/Divider';

function Posts() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className="blog_entry home">
      <ul className="case_list">
      {Object.entries(data).slice(0).reverse().map(([postSlug, {title, timestamp, body}]) => (
        <li key={postSlug}>
          <Link to={`/news/${postSlug}`}>
            <h2>{title}</h2>
            <p>{timestamp}</p>
            <p>{body.substring(0, 140)} {body.length >= 140 && '...'}</p>
            <p><span>Read More {">>"}</span></p>
          </Link>
          <Divider/>
        </li>
      ))}
      </ul>
    </div>
  );
}

export default Posts;