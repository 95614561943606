import React from "react";
import {useEffect} from 'react';
import '../assets/styles/Team.scss';

function Team() {
  useEffect(() => {
    document.title = "Sato's Film Studios | Team";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
      <div className="page_header">
        <img src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_banner.png" alt=""></img>
      </div>
      <div className="items_wrapper">
        <div>
          <h1>Meet the Team</h1>
          <p>
            Sato's Film Studios is an independent filmmaking & photography duo based in Dallas, Texas, U.S., with a passion for creating cinematic short and feature length films.
            We are always looking for creative talents to join the team either in-person or virtually around the world. Feel free to contact us for future collaborations, inquiries,
            and any other projects.
          </p>
          <div className="card_wrapper">
            <div className="card">
                <div className="text">
                    <img src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/team_icon_2.jpg" alt=""></img>
                    <h2>Yuji</h2>
                    <p>Director, Writer, Editor</p>
                    <p>
                      Favorite films: 2001: A Space Odyssey (1968), High and Low (1963), Memento (2000), Whiplash (2014), 1917 (2019), There Will Be Blood (2007)
                    </p>
                </div>
            </div>
            <div className="card">
                <div className="text">
                    <img src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/team_icon_1.jpg" alt=""></img>
                    <h2>Lacey</h2>
                    <p>Producer, Actress, Writer</p>
                    <p>
                      Favorite films: Pride & Prejudice (2005), Mean Girls (2004), Lady Bird (2017), Castle in the Sky (1986), The Iron Giant (1999), The Hunchback of Notre Dame (1996)
                    </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;