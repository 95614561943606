import React, {useEffect} from "react";
import { useParams } from "react-router";
import data from './data'

function Post() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  let {postSlug} = useParams();

  const fetched = data[postSlug];
  const {title, timestamp, body} = fetched;

  return (
    <div className="post_show">
      <h2 className="mt-5">{title}</h2>
      <h3>{timestamp}</h3>
      <p
        dangerouslySetInnerHTML={{__html: body}}
      />
    </div>
  );
}

export default Post;