import React, {useEffect} from "react";
import { Outlet } from "react-router-dom";

function News() {
  useEffect(() => {
    document.title = "Sato's Film Studios | News";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
      <div className="page_header">
        <img src="https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_banner.png" alt=""></img>
      </div>
      <div className="items_wrapper">
        <h1>News</h1>
        <Outlet />
      </div>
    </div>
  );
}

export default News;