import React from "react";
import {useEffect} from 'react';
import '../assets/styles/Home.scss';

import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

function Home() {
  useEffect(() => {
    document.title = "Sato's Film Studios";
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  var items = [
    {
        name: "https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_1_reduced.png",
    },
    {
        name: "https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_2_reduced.png",
    },
    {
        name: "https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_3_reduced.png",
    },
    {
        name: "https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/hero_4_reduced.png",
    }
  ]

  function Item(props) {
    return (
      <Paper>
        <img
          src={props.item.name}
          alt=""
          loading="lazy"
        />
      </Paper>
    )
  }

  return (
  <div>
    <Carousel className="home-carousel">
    {
      items.map( (item, i) => <Item key={i} item={item} /> )
    }
    </Carousel>
    <div className="home-container">
      <div className="paragraph">
        <h1>Storytelling through frames</h1>
        <p>
          Sato's Film Studios is an independent filmmaking & photography duo based in Dallas, Texas, U.S., with a passion for creating cinematic short and feature length films. 
          We are always looking for creative talents to join the team either in-person or virtually around the world. Feel free to contact us for future collaborations, inquiries,
          and any other projects.
        </p>
        <Box sx={{ flexGrow: 1 }} className="home-container-1">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <h1>Production Design</h1>
              <p>
                Working in close collaboration with directors and cinematographers, our dedicated team transforms visions into reality with unwavering precision and fervent passion. Whether it be the recreation of historical epochs, the visualization of futuristic landscapes, or the crafting of otherworldly realms, we continuously push the boundaries of creativity. Explore our gallery to immerse yourself in the artistry behind our productions and witness firsthand the transformative power of cinematic design.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img src='https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/pexels-kyle-loftus-2510428.jpg' alt="Film Crew" />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }} className="home-container-2">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src='https://satosfilm-assets.s3.us-west-2.amazonaws.com/img/profile/pexels-maksim-romashkin-13812398.jpg' alt="Film Crew" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h1>Filming</h1>
              <p>
                Our skilled team harness the power of light, composition, and movement to convey the essence of each moment with depth and clarity. With a keen eye for detail and a mastery of cinematic techniques, we collaborate seamlessly with clients to bring scripts to fruition. Whether capturing intimate character moments, epic action sequences, or breathtaking landscapes, our team ensures each frame is infused with cinematic magic. Explore our showcase to witness the artistry behind our filming process and immerse yourself in the captivating world of visual storytelling.
              </p>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  </div>
  );
}
export default Home;